import React from 'react';
import { IndustryViewContext, TemplateProps } from '../parser';
import { PathService } from '../services';
import { IndustryView } from '../views';

const Industry: React.FunctionComponent<TemplateProps<IndustryViewContext>> = ({
  location,
  pageContext,
}) => {
  PathService.set(location.pathname);
  return (
    <IndustryView
      content={JSON.parse(pageContext.content)}
      page={JSON.parse(pageContext.page)}
    />
  );
};

export default Industry;
